import React, {useEffect, useState} from 'react';
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import "./style.scss";
import {Line} from 'react-chartjs-2';
import apiService from "../../services/request";

const ReportView = ({formData, deviceData, closeReport}) => {
    const [data, setData] = useState({})

    useEffect(() => {
			const channel_colors = {
				"Ambient": "rgba(26, 188, 156,1.0)",
				"Temperature": "rgba(44, 62, 80,1.0)",
				"Zone 1": "rgba(52, 152, 219,1.0)",
				"Zone 2": "rgba(52, 73, 94,1.0)",
				"Zone 3": "rgba(241, 196, 15,1.0)",
				"Zone 4": "rgba(231, 76, 60,1.0)",
				"Zone 5": "rgba(255, 0, 0, 1.0)",
				"Zone 6": "rgba(0, 255, 0, 1.0)",
				"Zone 7": "rgba(0, 0, 255, 1.0)",
				"Zone 8": "rgba(255, 165, 0, 1.0)",
				"Zone 9": "rgba(128, 0, 128, 1.0)",
				"Zone 10": "rgba(0, 255, 255, 1.0)",
				"Zone 11": "rgba(255, 255, 0, 1.0)",
				"Zone 12": "rgba(255, 20, 147, 1.0)",
				"Zone 13": "rgba(64, 224, 208, 1.0)",
				"Zone 14": "rgba(139, 69, 19, 1.0)",
				"Zone 15": "rgba(64, 64, 64, 1.0)",
				"PH3 Flow": "rgba(50, 205, 50, 1.0)",
				"PH3 Pressure": "rgba(255, 215, 0, 1.0)",
				"PH3 Set": "rgba(178, 34, 34, 1.0)",
				"N2 Flow": "rgba(255, 127, 80, 1.0)",
				"N2 Pressure": "rgba(127, 255, 212, 1.0)",
				"Air Flow": "rgba(34, 139, 34, 1.0)",
				"Air Pressure": "rgba(255, 0, 255, 1.0)",
				"PH3 Inlet": "rgba(0, 0, 128, 1.0)",
				"PH3 Outlet": "rgba(221, 160, 221, 1.0)",
				"PH3 Ambient": "rgba(128, 128, 0, 1.0)",
				"Temp Outlet": "rgba(255, 140, 0, 1.0)",
			}
			if (deviceData && formData) {
				const startDateUTC = new Date(formData.startDate.getTime() - formData.startDate.getTimezoneOffset() * 60000).toISOString();
				const endDateUTC = new Date(formData.endDate.getTime() - formData.endDate.getTimezoneOffset() * 60000).toISOString();
				try {
					apiService.getReportChannelsData(
						deviceData.id,
						startDateUTC,
						endDateUTC,
						formData.channels
					).then(data => {
						let graph_data = {
							datasets: []
						};
						for (let [channel, points] of Object.entries(data)) {
							const channelData = deviceData.channels.find(el => el.name === channel);
							let new_dataset = {
								label: `${channelData.alias || channelData.name} [${channelData.unit}]`,
								borderColor: channel_colors[channel],
								backgroundColor: 'rgba(255, 255, 255, 0)',
								data: []
							}
							new_dataset.data = points.map(point => {
								const date = new Date(`${point.date}`);
								return {
									y: Number(point.value),
									x: date
								}
							})
							graph_data.datasets.push(new_dataset);
						}
						setData(graph_data)
					})
				} catch (e) {
					console.log(e)
				}
			}
		}, [deviceData, formData])

    return (
        <Container className="print-section">
            <Paper className="main-container">
                <section>
                    <h1>Report - {formData.treatment}</h1>
                    <div className="text-section">
                        <p>Data presented from {formData.startDate.toLocaleString()} to {formData.endDate.toLocaleString()}</p>
                        <p>Report generated by {formData.technician}, in {formData.location}, {formData.facility}</p>
                        <p>for {formData.customer} in {new Date().toLocaleString()} for {formData.treatment}</p>
                        <p>{formData.notes}</p>
                    </div>
                </section>
                <section>
                    <div className="channel-container">
                        <Line data={data} options={options} />
                    </div>
                </section>
                <div className="btn-group no-print">
                    <button className="btn-default" onClick={() => closeReport()}>Close report</button>
                    <button className="btn-default" onClick={window.print}>Print report</button>
                </div>
            </Paper>
        </Container>
    )
}

const options = {
		scales: {
			xAxes: [{
				type: 'time',
				time: {
					unit: 'hour',
					displayFormats: {
						hour: 'MMM D - h:mm a'
					}
				}
			}],
			yAxes : [{
				ticks : {
					beginAtZero : true
				}
			}]
		},
		responsive: true,
		maintainAspectRatio: false
	}

export default ReportView;

